import React, { useState } from "react";

import styles from "./PopUpSocialError.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PopUpSocialErrorProps {
  phrase: string;
  open: boolean;
  close: () => void;
}

const PopUpSocialError: React.FC<PopUpSocialErrorProps> = (props) => {
  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => props.close()}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content} col-12   `}>
              <span className="light-title fw-bold">{props.phrase}</span>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PopUpSocialError;
