import React, { useState } from "react";

import phone from "../../assets/phone.svg";

import styles from "./ExchangeRowBig.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ExchangePopUp from "../ExchangePopUp/ExchangePopUp";

interface ExchangeRowBigProps {
  name: string;
  surname: string;
  company: string;
  phonenumber: string;
  email: string;
}

const ExchangeRowBig: React.FC<ExchangeRowBigProps> = (props) => {
  const [popup, setPopup] = useState(false);

  return (
    <>
      <div
        className=" d-flex flex-row align-items-center justify-content-start  col-12 "
        style={{ height: "50px", borderBottom: "1px solid black" }}
      >
        <span className="col-xl-3 col-4 sub-title h-100 d-flex flex-row align-items-center justify-content-start">
          {props.name} {props.surname}
        </span>
        <span className="col-xl-2 col-3   text-xxl overflow-hidden h-100 d-flex flex-row align-items-center justify-content-start">
          {props.company}
        </span>
        <span className="col-xl-3  d-none col-4 text-xxl  overflow-hidden h-100 d-xl-flex flex-row align-items-center justify-content-start">
          {props.phonenumber && (
            <a
              href={`tel:${props.phonenumber}`}
              style={{ textDecoration: "none" }}
              className="cursor-pointer color-black d-flex flex-row align-items-center justity-content-start "
            >
              <img
                alt="phone"
                src={phone}
                style={{ height: "20px" }}
                className="me-3 d-xl-block d-none"
              />
              {props.phonenumber}
            </a>
          )}
        </span>
        <span className="col-xl-3 col-3 text-xxl overflow-hidden h-100 d-flex flex-row align-items-center justify-content-start">
          <a
            href={`mailto:${props.email}`}
            style={{ textDecoration: "none" }}
            className="color-black"
          >
            {props.email}
          </a>
        </span>
        <span className="col-xl-1 col-2   text-xxl overflow-hidden d-flex flex-row align-items-center justify-content-center h-100 ">
          <div
            className={`d-flex flex-row align-items-center justify-content-center col-xl-8 col-10  cursor-pointer ${styles.detailsBtn} `}
            onClick={() => setPopup(!popup)}
          >
            <FontAwesomeIcon
              icon={faPlus as IconProp}
              style={{ fontSize: "1.5rem" }}
            />
          </div>
        </span>
      </div>
      <ExchangePopUp
        open={popup}
        close={() => setPopup(!popup)}
        firstName={props.name}
        lastName={props.surname}
        company={props.company}
        phonenumber={props.phonenumber}
        email={props.email}
      />
    </>
  );
};

export default ExchangeRowBig;
