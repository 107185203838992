import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./Register.module.scss";
import axiosHelper from "../../helpers/axiosHelper";

import logo from "../../assets/LOGO_WHITE.png";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import {
  setPlatformContact,
  setPlatformUser,
} from "../../store/BusinessPlatformStore";
import PopUpSocialError from "../../components/PopUpSocialError/PopUpSocialError";

const Register: React.FC = () => {
  const redirect = useNavigate();
  const params = useParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [repeatPwd, setRepeatPwd] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [passwordFeedback, setPasswordFeedback] = useState("");
  const [feedbackColor, setFeedbackColor] = useState("");
  const [registerSubmitted, setRegisterSubmitted] = useState(false);
  const [popUpSocialError, setPopUpSocialError] = useState(false);
  const [phrase, setPhrase] = useState("");
  const [registerError, setRegisterError] = useState("");

  const handleRegister = async (e: any) => {
    e.preventDefault();
    setPwdError(false);
    setPrivacyError(true);
    if (privacy) {
      setPrivacyError(false);
    }

    if (password === repeatPwd && password.length) {
      try {
        if (privacy) {
          if (email && params.uuid) {
            const response = await axiosHelper.register(
              email,
              password,
              username,
              params.uuid
            );
            // console.log(response);
            setRegisterSubmitted(true);
          }
        } else {
          setPrivacyError(true);
        }
        // Salva il token nel localStorage
      } catch (error: any) {
        console.error("Register error", error);
        setRegisterError(error.response.data.msg);
        // alert("Register error, control your credentials");
      }
    } else {
      setPwdError(true);
    }
  };

  const controlPassword = (pwd: string) => {
    setPassword(pwd);
    passwordControl(pwd);
  };

  const passwordControl = (pwd: string) => {
    if (pwd === "") setPasswordFeedback("");
    const minLenght = 8;
    const regexMaiuscola = /[A-Z]/;
    const regexMinuscola = /[a-z]/;
    const regexNumber = /[0-9]/;
    const specialChar = /[!@#\$%\^\&*\)\(+=._-]/;

    let punteggio = 0;

    if (password.length >= minLenght) {
      punteggio++;
    }
    if (regexMaiuscola.test(pwd)) {
      punteggio++;
    }
    if (regexMinuscola.test(pwd)) {
      punteggio++;
    }
    if (regexNumber.test(pwd)) {
      punteggio++;
    }
    if (specialChar.test(pwd)) {
      punteggio++;
    }
    if (pwd.length > 0) {
      if (punteggio === 5) {
        setPasswordFeedback("Very secure password ");
        setFeedbackColor("#b2fc02");
      } else if (punteggio >= 3) {
        setPasswordFeedback("Secure password ");
        setFeedbackColor("#2273f5");
      } else {
        setPasswordFeedback("Weak password ");
        setFeedbackColor("#f52222");
      }
    } else {
      setPasswordFeedback("");
    }
  };

  const handleFacebookLogin = () => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/social/facebook`,
      "_self"
    );
  };

  const handleGoogleLogin = () => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/social/google`,
      "_self"
    );
  };

  useEffect(() => {
    if (params.uuid) {
      localStorage.setItem("contact_uuid", params.uuid);
    }
  }, []);

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-center col-12 bg-black ${styles.pageContainer}`}
      >
        <div
          className={`d-flex flex-column col-xl-8 col-10  align-items-center justify-content-start  ${styles.boxContainer}`}
        >
          <span
            className={`${styles.logoContainer} d-flex flex-row align-items-center justify-content-center col-xl-3 col-7  mt-xl-10 mt-7  `}
          >
            <img src={logo} />
          </span>
          {!registerSubmitted && (
            <>
              <p className="mt-10 light-title fw-bold col-xl-4 col-8 text-center mb-5 ">
                Create an account to get started!
              </p>
              <span
                className={`mt-xl-5 text-helvetica fw-bold col-xl-4 col-12 text-center mb-10  `}
              >
                Already have an account?
                <span
                  className={`color-dlcGreen cursor-pointer ${styles.signin}`}
                  onClick={() => redirect("/login")}
                >
                  Sign in
                </span>
              </span>
              <div className="col-xl-4 col-12  ">
                <form onSubmit={handleRegister} className="col-12 h-100 ">
                  <div className="d-flex flex-column align-items-center justify-content-start col-12">
                    <span className="sub-title d-flex flex-row align-items-center justify-content-start col-12 mb-3 mt-4">
                      Username *
                    </span>
                    <input
                      type="text"
                      placeholder="Username"
                      className={`${styles.inputField}  col-12`}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <span className="sub-title d-flex flex-row align-items-center justify-content-start col-12 mb-3 mt-4">
                      Email *
                    </span>
                    <input
                      type="email"
                      placeholder="Email"
                      className={`${styles.inputField}  col-12`}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="sub-title d-flex flex-row align-items-center justify-content-start col-12 mb-3 mt-4">
                      Password *
                    </span>
                    <input
                      type="password"
                      placeholder="Password"
                      className={`${styles.inputField} col-12`}
                      value={password}
                      onChange={(e) => controlPassword(e.target.value)}
                    />
                    {passwordFeedback !== "" && (
                      <span
                        className={`mt-2 `}
                        style={{ color: `${feedbackColor}` }}
                      >
                        {passwordFeedback}
                      </span>
                    )}
                    <span className="sub-title d-flex flex-row align-items-center justify-content-start col-12 mb-3 mt-4">
                      Repeat password *
                    </span>
                    <input
                      type="password"
                      placeholder="Repeat password"
                      className={`${styles.inputField} col-12`}
                      value={repeatPwd}
                      onChange={(e) => setRepeatPwd(e.target.value)}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-4 ">
                      <div className="col-12 d-flex flex-row align-items-start justify-content-start">
                        <label className={`${styles.checkboxContainer}  col-2`}>
                          <input
                            type="checkbox"
                            // checked={privacy}
                            onClick={() => setPrivacy(!privacy)}
                          />
                          <span className={`${styles.checkmark} `}></span>
                        </label>
                        <span className="col-10 " style={{ fontSize: "1rem" }}>
                          I confirm that i have read the{" "}
                          <a
                            href="https://dlctech.it/Privacy-Policy"
                            style={{ color: "#b2fc02" }}
                          >
                            privacy policy
                          </a>{" "}
                          and agree to the processing of my personal data by the
                          user or his company for the stated purposes. I can
                          revoke this consent at any time.
                        </span>
                      </div>
                    </div>
                    {pwdError && (
                      <div className="mt-5 text-helvetica  text-center">
                        Passwords don't match,
                        <br /> please re-enter them
                      </div>
                    )}
                    {privacyError && (
                      <div className="mt-5 text-helvetica col-8 text-center">
                        You must accept the privacy policy to create your
                        account
                      </div>
                    )}
                    {registerError !== "" && (
                      <div className="mt-5 text-helvetica col-8 text-center">
                        {registerError}
                      </div>
                    )}
                    <button
                      type="submit"
                      className={`${styles.submitBtn} sub-title mt-10 col-12 align-self-end`}
                    >
                      Create account
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-12 col-xl-4 d-flex flex-column justify-content-center align-items-center mt-5 mb-10">
                {/* <div className="col-12 ">
                  <FacebookLoginButton
                    style={{ borderRadius: "20px" }}
                    onClick={handleFacebookLogin}
                  />
                </div> */}
                <div className="col-12 ">
                  <GoogleLoginButton
                    style={{ borderRadius: "20px" }}
                    onClick={handleGoogleLogin}
                  />
                </div>
              </div>
            </>
          )}
          {registerSubmitted && (
            <>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <p className="mt-10 light-title fw-bold col-xl-5 col-12 text-start mb-5  ">
                  You must have received an email from noreply@dlctech.it
                  <br />
                  <br /> please open your mailbox and confirm your email.
                  <br />
                  <br />
                  <span className="sub-title mt-3">
                    Thanks, <br /> the DLC Tech team
                  </span>
                </p>
                <span className="sub-title mt-10 d-flex flex-row col-12 col-xl-5 align-items-center justify-content-center">
                  <span
                    className={`${styles.submitBtn} cursor-pointer sub-title d-flex flex-row col-6 align-items-center justify-content-center `}
                    onClick={() => redirect("/Login")}
                  >
                    Login
                  </span>
                </span>
              </div>
            </>
          )}
          <PopUpSocialError
            phrase={phrase}
            open={popUpSocialError}
            close={() => setPopUpSocialError(!popUpSocialError)}
          />
        </div>
      </div>
    </>
  );
};

export default Register;
