import React, { useEffect, useState } from "react";

import BusinessPlatformStore, {
  addUserExchanges,
  setCurrentMenu,
  setExchangePageNumber,
} from "../../store/BusinessPlatformStore";

import styles from "./Exchange.module.scss";
import { useStoreState } from "pullstate";
import {
  getExchangePageNumber,
  getUserCounter,
  getUserExchanges,
} from "../../store/Selector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ExchangeRowBig from "../../components/ExchangeRowBig/ExchangeRowBig";
import axiosHelper from "../../helpers/axiosHelper";

const Exchange: React.FC = () => {
  const userExchanges = useStoreState(BusinessPlatformStore, getUserExchanges);
  const userCounter = useStoreState(BusinessPlatformStore, getUserCounter);
  const exchangePageCharged = useStoreState(
    BusinessPlatformStore,
    getExchangePageNumber
  );
  const maxPage = Math.ceil(userCounter.exchange_number / 10);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState<Exchange[]>([]);
  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, userExchanges.length);

    setCurrentItems(userExchanges.slice(startIndex, endIndex));
  }, [page, userExchanges]);

  const getNextPage = async () => {
    if (page > exchangePageCharged) {
      await axiosHelper.getUserExchange(page).then((response) => {
        setExchangePageNumber(page);
        addUserExchanges(response.exchanges);
      });
    }
    setPage((prevPage) => Math.min(prevPage + 1));
  };

  const getPrevPage = () => {
    if (page > 1) setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    setCurrentMenu("Exchange");
  }, []);

  return (
    <>
      {/* <Header title="Exchanges List" /> */}
      {/* <div className="d-flex flex-row col-12"> */}
      {/* <PageSkeleton /> */}
      {/* <div className=" col-xl-10 col-12 bg-white p-xl-5 p-3 overflow-hidden"> */}
      <div
        className="col-12 d-flex flex-row mt-4 pb-3 "
        style={{ borderBottom: "1px solid black" }}
      >
        <div
          className={`col-xl-3 col-4  text-xxl d-flex flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          Name
        </div>
        <div
          className={`col-xl-2  col-3 text-xxl d-flex  flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          Company
        </div>
        <div
          className={`col-xl-3   text-xxl d-xl-flex d-none flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          Phone number
        </div>
        <div
          className={`col-xl-3 col-4  text-xxl d-flex flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          Email
        </div>
      </div>
      <div
        className="d-flex flex-column col-12 align-items-start justify-content-start "
        style={{ minHeight: "55vh" }}
      >
        {currentItems.map((item, index) => (
          <ExchangeRowBig
            key={index}
            name={item.name}
            surname={item.surname}
            company={item.company_name}
            phonenumber={item.phone_number}
            email={item.email}
          />
        ))}
      </div>
      <div
        className={`d-flex flex-row align-items-center justify-content-end  col-12 color-black text-xxl ${styles.pager}`}
      >
        <FontAwesomeIcon
          icon={faAngleLeft as IconProp}
          className="pe-3 "
          style={{ fontSize: "1.8rem" }}
          onClick={() => getPrevPage()}
        />
        <span className="fw-bold me-2">Pag {page}</span>|
        <span className="color-dark_grey_50 ms-2 fw-bold">{maxPage}</span>
        <FontAwesomeIcon
          icon={faAngleRight as IconProp}
          className="ps-3 "
          style={{ fontSize: "1.8rem" }}
          onClick={() => getNextPage()}
        />
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Exchange;
