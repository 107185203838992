import React, {  useState } from "react";
import styles from "./Onboarding.module.scss";
import axiosHelper from "../../helpers/axiosHelper";
import { useStoreState } from "pullstate";
import BusinessPlatformStore, {
  setPlatformContact,
} from "../../store/BusinessPlatformStore";
import { getPlatformContact } from "../../store/Selector";

interface AddNameProps {
  success: () => void;
}

const AddName: React.FC<AddNameProps> = (props) => {
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [name, setName] = useState(
    userContact.name !== null ? userContact.name : ""
  );
  const [surname, setSurname] = useState(
    userContact.surname !== null ? userContact.name : ""
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // console.log("submit name surname");
    if (name !== "" && surname !== "") {
      if (name !== userContact.name || surname !== userContact.surname) {
        let data: AddNameSurnameData = {
          name: name,
          surname: surname,
        };
        await axiosHelper.sendNameAndSurname(data).then((response) => {
          setPlatformContact(response.updated);
          props.success();
        });
      } else {
        props.success();
      }
    }
  };

  return (
    <>
      <div
        className={` d-flex flex-column align-items-center justify-content-start  col-12  ${styles.container}`}
      >
        <div className={`${styles.titleRow} `}>
          <span className="onboarding-title fw-bold  col-12 text-center">
            First, tell us a bit about you. <br /> What is your name?
          </span>
        </div>

        <form
          className="d-flex flex-column align-items-start justify-content-center  mt-10 col-12"
          onSubmit={handleSubmit}
        >
          <span className="text-xxl">First name</span>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`${styles.inputField} mt-3 col-12`}
          />
          <span className="mt-4 text-xxl ">Last name</span>
          <input
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            className={`${styles.inputField} mt-3 col-12`}
          />
          <div className="d-flex flex-row align-items-center justify-content-center col-12">
            <button
              className={`${
                name && surname ? styles.submitBtn : styles.submitBtnDisactive
              } col-12 sub-title`}
              disabled={!name && !surname}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddName;
