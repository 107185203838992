import React, { useState } from "react";
import styles from "./Onboarding.module.scss";
import axiosHelper from "../../helpers/axiosHelper";
import { getPlatformContact } from "../../store/Selector";
import { useStoreState } from "pullstate";
import BusinessPlatformStore, {
  setPlatformContact,
} from "../../store/BusinessPlatformStore";

interface AddCompanyProps {
  success: () => void;
}

const AddCompany: React.FC<AddCompanyProps> = (props) => {
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [company, setCompany] = useState(userContact.company !== null ? userContact.company : '');
  const [role, setRole] = useState(userContact.role !== null ? userContact.role : '');

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // console.log("submit name surname");
    if (role !== "" && company !== "") {
      if (role !== userContact.role || company !== userContact.company) {
        let data: AddCompanyData = {
          role: role,
          company: company,
        };
        await axiosHelper.sendRoleAndCompany(data).then((response) => {
          setPlatformContact(response.updated);
          props.success();
        });
      }else{
        props.success();
      }
    }
  };

  return (
    <>
      <div
        className={` d-flex flex-column align-items-center justify-content-start  col-12  ${styles.container}`}
      >
        <div className={`${styles.titleRow} `}>
          <span className="onboarding-title fw-bold  col-12 text-center">
            And about your role at your company?
          </span>
        </div>

        <form
          className="d-flex flex-column align-items-start justify-content-center  mt-10 col-12"
          onSubmit={handleSubmit}
        >
          <span className="text-xxl">Company</span>
          <input
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className={`${styles.inputField} mt-3 col-12`}
          />
          <span className="mt-4 text-xxl ">Role</span>
          <input
            type="text"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className={`${styles.inputField} mt-3 col-12`}
          />
          <div className="d-flex flex-row align-items-center justify-content-center col-12">
            <button
              className={`${
                role && company ? styles.submitBtn : styles.submitBtnDisactive
              } col-12 sub-title`}
              disabled={!company && !role}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCompany;
