import React, { useCallback, useEffect, useState } from "react";
import styles from "../Login/Login.module.scss";

import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate } from "react-router-dom";

const AddUuid: React.FC = () => {
  const [uuid, setUuid] = useState("");

  const redirect = useNavigate();

  useEffect(() => {
    const item = localStorage.getItem("contact_uuid");
    if (item) {
      setUuid(item);
    }
  }, []);

  useEffect(() => {
    // Rimuove il parametro #_=_
    if (window.location.hash === "#_=_") {
      window.history.replaceState(null, "", window.location.href.split("#")[0]);
    }
  }, []);

  const sendUuid = useCallback(async () => {
    await axiosHelper.sendUuid(uuid).then((response) => {
      redirect("/");
    });
  }, [uuid, redirect]);

  useEffect(() => {
    if (uuid.length > 0) {
      console.log(uuid);
      sendUuid();
    }
  }, [uuid, sendUuid]);

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-center col-12 bg-black ${styles.pageContainer}`}
      >
        <div
          className={`d-flex flex-column col-xl-8 col-10  align-items-center justify-content-start mt-10  ${styles.boxContainer}`}
        >
          one more second
        </div>
      </div>
    </>
  );
};

export default AddUuid;
