import React from "react";
import styles from "./Onboarding.module.scss";

import editProfile from "../../assets/editGreen.svg";

interface AddNameProps {
  success: () => void;
}

const CompleteOnboarding: React.FC<AddNameProps> = (props) => {
  return (
    <>
      <div
        className={` d-flex flex-column align-items-center justify-content-start  col-12  ${styles.container}`}
      >
        <div className={`${styles.titleRow} `}>
          <span className="onboarding-title fw-bold  col-12 text-center">
            Congrats!
            <br /> Your basic contact information has been charged! <br /> you
            can change it whenever you want in <br />
            <br />
            <img alt="editProfileIcon" src={editProfile} />
            &nbsp;Edit Profile section
          </span>
        </div>

        <div
          className={`${styles.appInfo} mt-10 d-flex flex-row col-12 align-items-center justify-content-center`}
        >
          <span className="sub-title   col-12 text-start ">
            If you have any problems finding the information or sections you
            need,visit the FAQS in
            <br />
            <br /> Account &#62; FAQ <br />
            <br />
            or
            <br />
            <br /> Account &#62; First Steps
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center col-12">
          <button
            className={`${styles.submitBtn} col-12 sub-title`}
            onClick={() => props.success()}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default CompleteOnboarding;
