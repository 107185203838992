import React, { useState } from "react";

import styles from "./LinksPopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axiosHelper from "../../helpers/axiosHelper";
import { setPlatformContact } from "../../store/BusinessPlatformStore";

interface AboutMePopUpProps {
  label1: string;
  link1: string;
  label2: string;
  link2: string;
  open: boolean;
  close: () => void;
}

const LinksPopUp: React.FC<AboutMePopUpProps> = (props) => {
  const [popup, setPopup] = useState(false);

  const [label1, setLabel1] = useState(props.label1);
  const [link1, setLink1] = useState(props.link1);
  const [label2, setLabel2] = useState(props.label2);
  const [link2, setLink2] = useState(props.link2);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    console.log("submit");
    let data: updateLinksData = {
      link1: link1,
      link2: link2,
      label1: label1,
      label2: label2,
    };
    console.log(data);
    await axiosHelper
      .updateLinks(data)
      .then((response) => {
        console.log(response.updated);
        setPlatformContact(response.updated);
        props.close();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => props.close()}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content} col-12   `}>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 pt-5 pt-xl-2">
                <span className="light-title fw-bold">Add Links</span>
              </div>
              <form onSubmit={handleSubmit} className="col-12">
                <div className="d-flex flex-column align-items-start justify-content-start mt-5  col-12">
                  <div className=" col-12  text text-center">
                    <b>Label</b> is what you see on your web interface <br />
                    <b>Link</b> is where you're redirect by clicking the Label.
                  </div>
                  <div
                    className={` d-flex flex-column col-12 align-items-start justify-content-start  col-12 ${styles.rettangolone} mt-3`}
                  >
                    <div className="d-flex flex-row col-12 align-items-center justify-content-center text-xxl">
                      Link 1
                    </div>

                    <div
                      className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                    >
                      <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                        Label
                      </span>
                      <span
                        className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                      >
                        <input
                          type="text"
                          className={`ps-xl-5 ${styles.inputField}`}
                          value={label1}
                          placeholder="Insert Label for Link 1 "
                          onChange={(e) => setLabel1(e.target.value)}
                        />
                      </span>
                    </div>
                    <div
                      className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                    >
                      <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                        Link
                      </span>
                      <span
                        className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                      >
                        <input
                          type="text"
                          className={`ps-xl-5 ${styles.inputField}`}
                          value={link1}
                          placeholder="Insert Link 1"
                          onChange={(e) => setLink1(e.target.value)}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className={`d-flex flex-column align-items-start justify-content-start col-12 ${styles.rettangolone} mt-3`}
                  >
                    <div className="d-flex flex-row col-12 align-items-center justify-content-center text-xxl">
                      Link 2
                    </div>
                    <div
                      className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                    >
                      <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                        Label 2
                      </span>
                      <span
                        className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                      >
                        <input
                          type="text"
                          className={`ps-xl-5 ${styles.inputField}`}
                          value={label2}
                          placeholder="Insert Label for Link 2"
                          onChange={(e) => setLabel2(e.target.value)}
                        />
                      </span>
                    </div>
                    <div
                      className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                    >
                      <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                        Link 2
                      </span>

                      <span
                        className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                      >
                        <input
                          type="text"
                          className={`ps-xl-5   ${styles.inputField}`}
                          value={link2}
                          placeholder="Insert Link 2 "
                          onChange={(e) => setLink2(e.target.value)}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-5 ">
                    <button
                      type="submit"
                      className={`${styles.submitBtn} col-xl-3 col-5 text-xxl fw-bold`}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default LinksPopUp;
