import React, { useEffect } from "react";
import styles from "./FacebookErrorPage.module.scss";

import logo from "../../assets/LOGO_WHITE.png";

import { useNavigate } from "react-router-dom";

const FacebookErrorPage: React.FC = () => {
  const redirect = useNavigate();

  useEffect(() => {
    // Rimuove il parametro #_=_
    if (window.location.hash === "#_=_") {
      window.history.replaceState(null, "", window.location.href.split("#")[0]);
    }
  }, []);

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-center col-12 bg-black ${styles.pageContainer}`}
      >
        <div
          className={`d-flex flex-column col-xl-8 col-10  align-items-center justify-content-start  ${styles.boxContainer}`}
        >
          <div className={`${styles.header} col-12`}>
            <span
              className={`${styles.logoContainer} d-flex flex-row align-items-center justify-content-center col-xl-3 col-7  mt-xl-10 mt-7  `}
            >
              <img alt="logo" src={logo} />
            </span>
          </div>

          <div className={`col-xl-4 col-12 ${styles.content}  `}>
            <span className="sub-title text-center ">
              It looks like an account with this email already exists, but it
              didn't login with Facebook.
              <br />
            </span>
          </div>
          <div className="col-12 col-xl-4 d-flex flex-column justify-content-center align-items-center ">
            <div
              className={`${styles.submitBtn} d-flex flex-row align-items-center justify-content-center col-10 sub-title cursor-pointer`}
              onClick={() => redirect("/")}
            >
              Riprova
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacebookErrorPage;
