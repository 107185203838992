import React, { useState } from "react";

import styles from "./LogoutPopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate } from "react-router-dom";

interface LogoutPopUpProps {
  open: boolean;
  close: () => void;
}

const LogoutPopUp: React.FC<LogoutPopUpProps> = (props) => {
  const [popup, setPopup] = useState(false);
  const redirect = useNavigate();

  const handleLogout = async () => {
    console.log("logout");
    await axiosHelper.logout().then((response) => {
      redirect("/Login");
    });
  };

  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => {
                props.close();
              }}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content}  col-12 `}>
              <span className="d-flex flex-row col-12 align-items-center justify-content-center light-title fw-bold mt-3">
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket as IconProp}
                  fontSize={"30px"}
                  className="color-black me-4"
                />
                Logout
              </span>
              <div className="d-flex flex-column align-items-center justify-content-center col-12">
                <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-5">
                  Are you sure to logout ?
                </div>
                <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-5">
                  <button
                    type="submit"
                    onClick={() => handleLogout()}
                    className={`${styles.submitBtn} d-flex flex-row align-items-center justify-content-center fw-bold text-xxl col-7 col-xl-4`}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default LogoutPopUp;
