import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";

const VerifyUpdateEmail: React.FC = () => {
  const params = useParams();

  const redirect = useNavigate();

  // useEffect(() => {
  //   console.log(params.token);
  // }, []);

  useEffect(() => {
    veifyEmail();
  });

  const veifyEmail = async () => {
    if (params.token) {
      await axiosHelper.verifyNewEmail(params.token).then((response) => {
        redirect("/Login");
      });
    } else {
      console.log("no token");
    }
  };

  return (
    <>
      <p>ciao</p>
    </>
  );
};

export default VerifyUpdateEmail;
