import { Store } from "pullstate";

export interface IBusinessPlatformStore {
  user: User;
  contact: Contact;
  currentMenu: string;
  last5Exchanges: Exchange[];
  userExchanges: Exchange[];
  exchangePageNumber: number;
  userCounter: Counter;
}

const BusinessPlatformStore = new Store<IBusinessPlatformStore>({
  user: {
    username: "",
    email: "",
    contact_uuid: "",
  },
  contact: {
    name: "",
    surname: "",
    email: "",
    company: "",
    facebook: "",
    instagram: "",
    imageurl: "",
    logourl: "",
    mode: "",
    phone: "",
    role: "",
    whatsapp: 0,
    uuid: "",
    website: "",
    workmail: "",
    label_link1: "",
    label_link2: "",
    link1: "",
    link2: "",
    linkedin: "",
    twitter: "",
    youtube: "",
    tiktok: "",
    prefix: "",
    file1url: "",
    file2url: "",
    file3url: "",
    web_interface_name: "",
    file1label: "",
    file2label: "",
    file3label: "",
    workphone: "",
    workprefix: "",
  },
  currentMenu: "home",
  last5Exchanges: [],
  userExchanges: [],
  exchangePageNumber: 0,
  userCounter: {
    contact_uuid: "",
    global_click: 0,
    add_contact_click: 0,
    exchange_click: 0,
    exchange_number: 0,
  },
});

export default BusinessPlatformStore;

export const setPlatformUser = (user: User) => {
  BusinessPlatformStore.update((s) => {
    s.user = user;
  });
};

export const setPlatformContact = (contact: Contact) => {
  BusinessPlatformStore.update((s) => {
    s.contact = contact;
  });
};

export const setCurrentMenu = (menu: string) => {
  BusinessPlatformStore.update((s) => {
    s.currentMenu = menu;
  });
};

export const setLast5Exchanges = (exchanges: Exchange[]) => {
  BusinessPlatformStore.update((s) => {
    s.last5Exchanges = exchanges;
  });
};

export const setUserExchanges = (exchanges: Exchange[]) => {
  BusinessPlatformStore.update((s) => {
    s.userExchanges = exchanges;
  });
};

export const addUserExchanges = (exchanges: Exchange[]) => {
  BusinessPlatformStore.update((s) => {
    s.userExchanges = [...s.userExchanges, ...exchanges];
  });
};

export const setExchangePageNumber = (page: number) => {
  BusinessPlatformStore.update((s) => {
    s.exchangePageNumber = page;
  });
};

export const updateUserImage = (image: string) => {
  BusinessPlatformStore.update((s) => {
    s.contact.imageurl = image;
  });
};

export const updateUserLogo = (image: string) => {
  BusinessPlatformStore.update((s) => {
    s.contact.logourl = image;
  });
};

export const updateUserFiles = (file: string, fileNumber: number) => {
  BusinessPlatformStore.update((s) => {
    if (fileNumber === 1) {
      s.contact.file1url = file;
    } else if (fileNumber === 2) {
      s.contact.file2url = file;
    } else {
      s.contact.file3url = file;
    }
  });
};

export const setUserCounter = (counter: Counter) => {
  BusinessPlatformStore.update((s) => {
    s.userCounter = counter;
  });
};
