import React, { useState } from "react";

import styles from "./ExchangePopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ExchangeRowBigProps {
  firstName: string;
  lastName: string;
  company: string;
  phonenumber: string;
  email: string;
  open: boolean;
  close: () => void;
}

const ExchangePopUp: React.FC<ExchangeRowBigProps> = (props) => {
  const [popup, setPopup] = useState(false);

  const downloadContact = () => {
    let vcard = `BEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:`;

    if (props.firstName && props.lastName) {
      vcard =
        vcard +
        `BEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:` +
        props.firstName +
        ` ` +
        props.lastName +
        `\nN;CHARSET=UTF-8:` +
        props.lastName +
        `;` +
        props.firstName +
        `;;;`;
    }
    if (props.email) {
      vcard =
        vcard + `\nEMAIL;CHARSET=UTF-8;type=email,INTERNET:` + props.email;
    }

    if (props.phonenumber) {
      vcard = vcard + `\nTEL;TYPE=phone number,VOICE:` + props.phonenumber;
    }

    if (props.company) {
      vcard = vcard + `\nORG;CHARSET=UTF-8:` + props.company;
    }

    vcard = vcard + `\nNOTE;CHARSET=UTF-8:Created by DLC Tech`;
    vcard = vcard + `\nEND:VCARD`;

    let blob = new Blob([vcard], { type: "text/vcard" });

    let url = URL.createObjectURL(blob);
    const newLink = document.createElement("a");
    newLink.download = props.firstName + props.lastName + ".vcf";
    newLink.textContent = props.firstName + props.lastName;
    newLink.href = url;
    newLink.click();
  };
  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => props.close()}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content} col-12   `}>
              <div className="d-flex flex-row col-12 light-title fw-bold align-items-center justify-content-center color-dark_grey_50 pt-5 pt-xl-2 ">
                Exchange Details
              </div>
              <div className="d-flex flex-row col-12  mt-10">
                <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                  <span className="text-xxl  d-flex flex-row col-12 align-items-center justify-content-start ">
                    <span className="col-xl-3 col-4">
                      <b>First Name:</b>
                    </span>
                    {props.firstName}
                  </span>
                  <span className="text-xxl mt-5  d-flex flex-row col-12 align-items-center justify-content-start">
                    <span className="col-xl-3 col-4">
                      <b>Last Name:</b>
                    </span>
                    {props.lastName}
                  </span>
                  <span className="text-xxl mt-5  d-flex flex-row col-12 align-items-center justify-content-start">
                    <span className="col-xl-3 col-4">
                      <b>Company:</b>
                    </span>
                    {props.company}
                  </span>
                  <span className="text-xxl mt-5  d-flex flex-row col-12 align-items-center justify-content-start">
                    <span className="col-xl-3 col-4">
                      <b>Email:</b>
                    </span>
                    {props.email}
                  </span>
                  <span className="text-xxl mt-5  d-flex flex-row col-12 align-items-center justify-content-start">
                    <span className="col-xl-3 col-4">
                      <b>Phone number:</b>
                    </span>
                    {props.phonenumber}
                  </span>
                  <div className="text-xxl mt-5 pt-xl-0 pt-5  d-flex flex-row col-12 align-items-center justify-content-center">
                    <span
                      className={`${styles.downloadContactBtn} text-xxl col-7 col-xl-5 cursor-pointer`}
                      onClick={() => downloadContact()}
                    >
                      <FontAwesomeIcon
                        icon={faDownload as IconProp}
                        className="pe-2"
                      />
                      <span className="d-flex flex-row h-100 align-items-center justify-content-center ">
                        Download contact
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ExchangePopUp;
