import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import BusinessPlatformStore, {
  setCurrentMenu,
  setPlatformContact,
  setPlatformUser,
} from "../../store/BusinessPlatformStore";
import { useStoreState } from "pullstate";
import { getCurrentMenu } from "../../store/Selector";
import logo from "../../assets/LOGO_WHITE.png";
import logoBlack from "../../assets/LOGO_NERO.png";
import phone from "../../assets/phone.svg";
import info from "../../assets/info.svg";
import user from "../../assets/user.svg";
import UserPopUp from "../UserPopUp/UserPopUp";

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const currentMenu = useStoreState(BusinessPlatformStore, getCurrentMenu);

  const redirect = useNavigate();

  const [popUpOpen, setPopUpOpen] = useState(false);

  useEffect(() => {
    login();
  }, []);

  const login = async () => {
    await axiosHelper
      .verifyToken()
      .then((response) => {
        console.log(response);
        if (response.user) setPlatformUser(response.user);
        if (response.contact) setPlatformContact(response.contact);
        // redirect("/");
        // window.history.replaceState({}, document.title, "/"); // Rimuovi il token dall'URL);}
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 400) {
          console.log("signin");
        }
      });
  };

  return (
    <>
      <div
        className=" d-flex flex-row  align-items-center col-12"
        style={{ height: "10vh" }}
      >
        <div
          className={`col-2 d-xl-flex d-none h-100   ${styles.logoContainer}`}
        >
          <img
            alt="DLCLogo"
            src={logo}
            className="cursor-pointer"
            onClick={() => {
              redirect("/");
              setCurrentMenu("Home");
            }}
          />
        </div>
        <div
          className={`d-flex d-xl-none  col-4 col-md-3  ${styles.logoContainerWhite}`}
        >
          <img
            alt="DLCLogo"
            src={logoBlack}
            className="cursor-pointer"
            onClick={() => {
              redirect("/");
              setCurrentMenu("Home");
            }}
          />
        </div>
        <div
          className={`col-xl-10 col-12  h-100  bg-white   ${styles.infoContainer}`}
        >
          <div
            className={`d-flex flex-row align-items-center justify-content-xl-start justify-content-xl-start justify-content-end col-xl-5 col-8     light-title fw-bold ${styles.title}`}
          >
            {currentMenu}
          </div>
          <div className="d-xl-flex d-none flex-row align-items-center justify-content-end col-xl-7  ">
            {/* <div className={`${styles.contactBtn}  me-xl-5 me-3 color-black`}> */}
            <a
              href={`mailto:assistance@dlctech.it`}
              style={{ textDecoration: "none" }}
              className={`col-12 color-black ${styles.contactBtn} me-xl-5 me-3 color-black`}
            >
              <img alt="phone" src={phone} className="me-2" /> Contact Us
            </a>
            {/* </div> */}
            <img alt="info" src={info} className={`${styles.iconInfo} me-4 ms-4`} />
            <div
              className={`${styles.iconUserContainer} ms-3`}
              onMouseEnter={() => setPopUpOpen(true)}
              onMouseLeave={() => setPopUpOpen(false)}
            >
              <img alt="user" src={user} className={`${styles.iconUser} `} />
            </div>
          </div>
        </div>
        {popUpOpen && (
          <div
            onMouseEnter={() => setPopUpOpen(true)}
            onMouseLeave={() => setPopUpOpen(false)}
            style={{ border: "1px solid red!important", zIndex: 2 }}
          >
            <UserPopUp />
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
