import React, { useState } from "react";
import styles from "./Onboarding.module.scss";

import logo from "../../assets/LOGO_WHITE.png";

import { useNavigate } from "react-router-dom";

import AddName from "../../components/Onboarding/AddName";

import AddCompany from "../../components/Onboarding/AddCompany";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import AddImage from "../../components/Onboarding/AddImage";
import AddPhoneNumber from "../../components/Onboarding/AddPhoneNumber";
import CompleteOnboarding from "../../components/Onboarding/CompleteOnboarding";

const Onboarding: React.FC = () => {
  const redirect = useNavigate();

  const [processN, setProcessN] = useState(0);

  const addNameSuccess = () => {
    if (processN < 4) {
      setProcessN(processN + 1);
    } else {
      redirect("/");
    }
  };

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-center col-12 bg-black ${styles.pageContainer}`}
      >
        <div
          className={`d-flex flex-column col-xl-8 col-10  align-items-center justify-content-start  ${styles.boxContainer}`}
        >
          <div className={`${styles.header} col-12 `}>
            <span
              className={`${styles.logoContainer} d-flex  flex-row align-items-center justify-content-center col-xl-3 col-7  mt-xl-10 mt-7  `}
            >
              {processN > 0 && (
                <span
                  className={`${styles.backBtn} cursor-pointer`}
                  onClick={() => setProcessN(processN - 1)}
                >
                  <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                </span>
              )}
              <img alt="logo" src={logo} />
              {processN > 0 && processN < 4 && (
                <span
                  className={`${styles.skipBtn} text-xxl fw-bold cursor-pointer`}
                  onClick={() => setProcessN(processN + 1)}
                >
                  skip
                </span>
              )}
            </span>

            {/* <p className="mt-3 sub-title col-xl-4 col-8 text-center mb-4  "> */}
            {/* Don't have an account?<br/> <span className="color-dlcgreen cursor-pointer" style={{textDecoration:'underline'}} onClick={() => redirect('/register')}> Register</span> */}
            {/* </p> */}
          </div>

          <div className={`col-xl-5 col-12 ${styles.content}  `}>
            {processN === 0 && <AddName success={() => addNameSuccess()} />}
            {processN === 1 && <AddCompany success={() => addNameSuccess()} />}
            {processN === 2 && <AddImage success={() => addNameSuccess()} />}
            {processN === 3 && (
              <AddPhoneNumber success={() => addNameSuccess()} />
            )}
            {processN === 4 && (
              <CompleteOnboarding success={() => addNameSuccess()} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
