import React from "react";

interface ExchangeRowProps {
  name: string;
  surname: string;
  email: string;
}

const ExchangeRow: React.FC<ExchangeRowProps> = (props) => {
  return (
    <>
      <div
        className=" d-flex flex-row align-items-center justify-content-between  col-12"
        style={{ height: "40px", borderBottom: "1px solid white" }}
      >
        <span className="text-xxl">
          {props.name} {props.surname}
        </span>
        <span className="text-xxl">{props.email}</span>
      </div>
    </>
  );
};

export default ExchangeRow;
