import React, { useState } from "react";
import styles from "./Onboarding.module.scss";
import axiosHelper from "../../helpers/axiosHelper";
import { useStoreState } from "pullstate";
import BusinessPlatformStore, {
  setPlatformContact,
} from "../../store/BusinessPlatformStore";
import { getPlatformContact } from "../../store/Selector";

interface AddNameProps {
  success: () => void;
}

const AddPhoneNumber: React.FC<AddNameProps> = (props) => {
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [phone, setPhone] = useState(
    userContact.phone !== null ? userContact.phone : ""
  );
  const [prefix, setPrefix] = useState(
    userContact.prefix !== null ? userContact.prefix : ""
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // console.log("submit name surname");
    if (prefix !== "" && phone !== "") {
      if (prefix !== userContact.prefix || phone !== userContact.phone) {
        let data: AddPrefixPhoneData = {
          phone: phone,
          prefix: prefix,
        };
        await axiosHelper.sendPrefixAndPhone(data).then((response) => {
          setPlatformContact(response.updated);
          props.success();
        });
      } else {
        props.success();
      }
    }
  };

  const handlePrefix = (e: string) => {
    if (!e.startsWith("+")) {
      setPrefix("+" + e);
    } else {
      setPrefix(e);
    }
  };

  return (
    <>
      <div
        className={` d-flex flex-column align-items-center justify-content-start  col-12  ${styles.container}`}
      >
        <div className={`${styles.titleRow} `}>
          <span className="onboarding-title fw-bold  col-12 text-center">
            Nearly done!<br/> Lastly, add your primary phone number.
          </span>
        </div>

        <form
          className="d-flex flex-column align-items-start justify-content-center  mt-10 col-12"
          onSubmit={handleSubmit}
        >
          <div
            className={`d-flex flex-column col-12 align-items-start justify-content-start mt-5 ${styles.inputRow}`}
          >
            <span className="col-12   text-center  d-flex flex-row align-items-center text-xxl  ">
              Mobile number
            </span>

            <span
              className={`col-12  mt-3 d-flex flex-row align-items-center text-xxl overflow-hidden ${styles.inputContainer}`}
            >
              <span
                className={`col-3 d-flex  flex-row align-items-center justify-content-center ${styles.prefix}`}
              >
                <input
                  type="text"
                  className={`ps-xl-5 col-3  ${styles.inputField}`}
                  value={prefix}
                  placeholder=" prefix "
                  onChange={(e) => handlePrefix(e.target.value)}
                />
              </span>
              <span
                className={`col-9 d-flex  flex-row align-items-center justify-content-center`}
              >
                <input
                  type="text"
                  className={`ps-xl-5 col-9  ${styles.inputField}`}
                  value={phone}
                  required
                  placeholder="mobile number"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </span>
            </span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center col-12">
            <button
              className={`${
                prefix && phone ? styles.submitBtn : styles.submitBtnDisactive
              } col-12 sub-title`}
              disabled={!prefix && !phone}
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddPhoneNumber;
