import React, { useCallback, useEffect, useRef, useState } from "react";

import BusinessPlatformStore, {
  setCurrentMenu,
  setLast5Exchanges,
  setUserCounter,
  setUserExchanges,
} from "../../store/BusinessPlatformStore";

import styles from "./Home.module.scss";
import { useStoreState } from "pullstate";
import {
  getLast5Exchanges,
  getPlatformContact,
  getPlatformUser,
  getUserCounter,
} from "../../store/Selector";

import eye from "../../assets/eye.svg";
import edit from "../../assets/editBlack.svg";
import arrow from "../../assets/arrowBlack.svg";

import copy from "../../assets/copy.svg";
import download from "../../assets/download.svg";
import whatsapp from "../../assets/whatsapp.svg";
import mail from "../../assets/mail.svg";

import users from "../../assets/usersWhite.svg";
import analytics from "../../assets/analyticsWhite.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ExchangeRow from "../../components/ExchangeRow/ExchangeRow";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { QRCodeCanvas } from "qrcode.react";

const Home: React.FC = () => {
  const platformUser = useStoreState(BusinessPlatformStore, getPlatformUser);
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const fiveExchanges = useStoreState(BusinessPlatformStore, getLast5Exchanges);
  const userCounter = useStoreState(BusinessPlatformStore, getUserCounter);
  const [co2Saved, setCO2Saved] = useState(0);
  const [contactLink, setContactLink] = useState("");
  const qrRef = useRef<HTMLCanvasElement>(null);

  const redirect = useNavigate();

  useEffect(() => {
    if (!fiveExchanges.length) getLastExchanges();
  }, [fiveExchanges]);

  const getLastExchanges = async () => {
    await axiosHelper.getLast5Exchanges().then((response) => {
      // console.log(response.exchanges);
      setLast5Exchanges(response.exchanges);
    });
  };

  useEffect(() => {
    if (userContact.web_interface_name && userContact.uuid) {
      setContactLink(
        `https://businesscard.dlctech.it/${userContact.web_interface_name}/${userContact.uuid}`
      );
    }
  }, [userContact]);

  const goToExchange = async () => {
    await axiosHelper.getUserExchange(0).then((response) => {
      console.log(response);
      setUserExchanges(response.exchanges);
      redirect("/exchange-list");
      setCurrentMenu("exchange");
    });
  };

  useEffect(() => {
    if (userContact.name === null || userContact.surname === null) {
      redirect("/onboarding");
    }
  }, [redirect, userContact]);

  const calculateSaved = useCallback(() => {
    const paperCard = userCounter.exchange_number + userCounter.global_click;
    if (paperCard > 0) {
      setCO2Saved(Math.round(paperCard * 0.0063 * 1000) / 1000);
    } else {
      setCO2Saved(0);
    }
  }, [userCounter]);

  useEffect(() => {
    console.log(userCounter);
    if (userCounter.contact_uuid === "") {
      getCounter();
    }
    calculateSaved();
  }, [userCounter, calculateSaved]);

  const getCounter = async () => {
    await axiosHelper.getCounters().then((response) => {
      // console.log(response.counter);
      setUserCounter(response.counter);
    });
  };

  const downloadQRCode = () => {
    if (!qrRef.current) return;

    const canvas = qrRef.current;
    const url = canvas.toDataURL("image/png"); // Ottieni l'immagine come data URL

    const link = document.createElement("a");
    link.href = url;
    link.download = `${platformUser.username}.png`;
    link.click();
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(contactLink)
      // .then(() => {
      //   alert("Link copiato negli appunti!");
      // })
      .catch((error) => {
        console.error("Errore durante la copia del link", error);
      });
  };

  useEffect(() => {
    setCurrentMenu("Home");
  }, []);

  const sendWhatsappMsg = () => {
    const message = `Hello, this is my contact informations page! https://businesscard.dlctech.it/cotact/${userContact.uuid}`; // Messaggio predefinito
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  const sendEmailMsg = () => {
    const subject = " Contact Informations ";
    const message = `Hello! this is my contact informations page! https://businesscard.dlctech.it/cotact/${userContact.uuid}`;
    const mailtoURL = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;
    window.open(mailtoURL, "_blank");
  };

  return (
    <>
      <div className="d-flex flex-row flex-wrap col-12  align-items-center justify-content-between">
        <div className="col-xl-5 col-12  ">
          <div className={`d-flex flex-column   ${styles.businesscardRect}`}>
            <div className="d-flex flex-row col-12 align-items-start justify-content-start">
              <div className="d-flex flex-row col-xl-4 col-4  h-100">
                <div className={`${styles.imageContainer} `}>
                  {userContact.imageurl ? (
                    <img alt="userImage" src={userContact.imageurl} />
                  ) : (
                    <div className="d-flex flex-row align-items-center justify-content-center col-12 h-100 bg-light-gray-3">
                      <FontAwesomeIcon
                        icon={faUser as IconProp}
                        className="color-white"
                        style={{ fontSize: "12rem" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`d-flex   flex-column align-items-start justify-content-start col-8   ${styles.otherContainer}`}
              >
                <span className="sub-title fw-bold">
                  {userContact.name} {userContact.surname}
                </span>
                <span
                  className={`mt-3 ${styles.digitalRound}  text-s justify-content-start p-3`}
                >
                  Your digital business card
                </span>
                <span
                  className={`  ${styles.twoBtns} col-12 d-xl-flex d-none flex-row align-items-center justify-content-between`}
                >
                  <span
                    className={`${styles.previewBtn}`}
                    onClick={() => {
                      window.open(
                        `https://betabusiness.dlctech.it/contact/${userContact.uuid}/false`,
                        "_blank"
                      );
                    }}
                  >
                    <img alt="eyeIcon" src={eye} className="me-2" />
                    Preview
                  </span>
                  <span
                    className={`${styles.editBtn} `}
                    onClick={() => {
                      redirect("/edit-profile");
                      setCurrentMenu("edit");
                    }}
                  >
                    <img alt="editIcon" src={edit} className="me-2" />
                    Edit Profile
                  </span>
                </span>
              </div>
            </div>
            <div
              className={`col-12  d-xl-none d-flex flex-row align-items-center justify-content-center `}
            >
              <span
                className={`col-12 d-xl-none d-flex flex-row align-items-center justify-content-between`}
              >
                <span className={`${styles.previewBtn} col-5`}>
                  <img alt="eyeIcon" src={eye} className="me-2" />
                  Preview
                </span>
                <span
                  className={`${styles.editBtn} col-5 `}
                  onClick={() => {
                    redirect("/edit-profile");
                    setCurrentMenu("edit");
                  }}
                >
                  <img alt="editIcon" src={edit} className="me-2" />
                  Edit Profile
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-12 mt-xl-0 mt-5  d-flex flex-row align-items-center justify-content-end">
          <div className={`d-flex flex-row   ${styles.shareRect}`}>
            <div
              className={`d-flex flex-column ps-xl-4 col-12  align-items-start justify-content-start ${styles.shareContent}`}
            >
              <div
                className={`d-flex flex-row align-items-center justify-content-start  col-12 ${styles.titleRow} `}
              >
                <img alt="arrowIcon" src={arrow} className="me-4" />
                <span className="light-title fw-bold">Share Profile</span>
              </div>
              <div
                className={`mt-4 d-flex flex-row align-items-center justify-content-start  col-12 ${styles.qrRow} overflow-hidden`}
              >
                <div className="d-flex flex-row h-100 flex-wrap col-xl-1 col-0"></div>
                <div
                  className={`d-flex flex-row h-100       ${styles.qrContainer}`}
                >
                  <div>
                    <QRCodeCanvas ref={qrRef} value={contactLink} size={100} />
                  </div>
                  {/* <img src={qr} /> */}
                </div>
                <div
                  className={`d-flex flex-row h-100   ms-5  col-xl-8 col-md-9 col-sm-8 col-7 ps-xl-0 ps-4 align-items-center justify-content-center `}
                >
                  <div className={`${styles.shareBtn} col-12 p-3`}>
                    <span className={`col-11  text ${styles.stringValue}`}>
                      {/* FARE DINAMICO */}
                      {contactLink}
                    </span>
                    <span
                      className={`col-1 ${styles.copybtn} cursor-pointer`}
                      onClick={() => copyToClipboard()}
                    >
                      <img alt="copyIcon" src={copy} />
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`mt-4 d-flex flex-row align-items-center justify-content-between  col-12 ${styles.downloadRow}`}
              >
                <div className="d-flex flex-row col-4  h-100 align-items-center justify-content-start">
                  <div
                    className={`d-flex flex-row  col-xl-12  ${styles.downloadBtn} p-xl-0  p-3 align-items-center justify-content-center text`}
                    onClick={() => downloadQRCode()}
                  >
                    <img alt="downloadIcon" src={download} className="me-2 " />{" "}
                    Download
                  </div>
                </div>
                <div className="d-flex flex-row col-xl-7 col-8  align-items-center justify-content-end pe-xl-4 ">
                  <img
                    alt="whatsAppIcon"
                    src={whatsapp}
                    className={`${styles.whatsappIcon} cursor-pointer `}
                    onClick={() => sendWhatsappMsg()}
                  />
                  <img
                    alt="mailIcon"
                    src={mail}
                    className={`${styles.socialIcon} me-4 cursor-pointer`}
                    onClick={() => sendEmailMsg()}
                  />
                  {/* <img
                    src={instagram}
                    className={`${styles.socialIcon} me-4 cursor-pointer`}
                    onClick={() => sendIgMsg()}
                  /> */}
                  {/* <div
                    className={`${styles.otherOpt} d-flex flex-row align-items-center justify-content-center color-white`}
                    onClick={() => setShareOpen(!shareOpen)}
                  >
                    <FontAwesomeIcon
                      icon={(shareOpen ? faAngleUp : faAngleDown) as IconProp}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-xl-row flex-column  col-12  mt-5 align-items-start justify-content-start"
        // style={{ height: "350px" }}
      >
        <div className="col-xl-5 col-12 ">
          <div
            className={`${styles.exchangesRect} d-flex flex-column align-items-start justify-content-start `}
          >
            <div
              className={`d-flex flex-row col-12  align-items-center flex-wrap justify-content-between ${styles.titleRow}`}
            >
              <span className={`col-7 d-flex flex-row align-items-center `}>
                <img alt="usersIcon" src={users} />
                <span className="ps-4 light-title fw-bold">Exchanges</span>
              </span>
              <span className="col-5 ">
                <div
                  className={`${styles.descBtn} d-flex flex-row align-items-center text justify-content-center color-dlcgreen p-4 text-center p-xl-0`}
                >
                  Latest added contacts
                </div>
              </span>
            </div>
            <div
              className={`d-flex flex-column align-items-start justify-content-start col-12 ${styles.contactsContainer} mt-4 `}
            >
              {fiveExchanges.map((item, index) => (
                <ExchangeRow
                  key={index}
                  name={item.name}
                  surname={item.surname}
                  email={item.email}
                />
              ))}
            </div>
            <div
              className={`${styles.viewAllBtnContainer} d-flex flex-row col-12 align-items-center justify-content-end `}
            >
              <span
                className={`${styles.viewAllBtn} d-flex flex-row align-items-center justify-content-center col-3`}
                onClick={() => {
                  goToExchange();
                }}
              >
                View all
                <FontAwesomeIcon
                  icon={faArrowRight as IconProp}
                  className="ms-2"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-12 mt-5 mt-xl-0 mb-10 mb-xl-0 d-flex flex-column  align-items-end justify-content-start  ">
          <div
            className={`d-flex flex-column align-items-start justify-content-start ${styles.analyticsRect}`}
          >
            <div
              className={`d-flex flex-row  col-12 ${styles.titleRow} align-items-center justify-content-start`}
            >
              <img alt="analyticsIcon" src={analytics} />
              <span className="ms-3 light-title fw-bold">Analytics</span>
            </div>
            <div
              className={`d-flex flex-row align-items-center justify-content-around col-12 ${styles.dataRow} `}
            >
              <div
                className={`${styles.tapsCounter} d-flex flex-column align-items-center justify-content-start `}
              >
                <span
                  className={`d-flex flex-row align-items-center justify-content-start col-12 sub-title fw-normal ${styles.firstRow}`}
                >
                  Tap
                </span>
                <span
                  className={`d-flex flex-row align-items-center justify-content-center col-12 mt-3 text-xxl fw-bold ${styles.lastRow}`}
                >
                  {userCounter.global_click}
                </span>
              </div>
              <div
                className={`${styles.tapsCounter} d-flex flex-column align-items-center justify-content-start `}
              >
                <span
                  className={`d-flex flex-row align-items-center justify-content-start col-12 sub-title fw-normal  ${styles.firstRow}`}
                >
                  Exchange number
                </span>
                <span
                  className={`d-flex flex-row align-items-center justify-content-center  col-12 mt-3 text-xxl fw-bold ${styles.lastRow}`}
                >
                  {userCounter.exchange_number}
                </span>
              </div>
              <div
                className={`${styles.tapsCounter} d-flex flex-column align-items-center justify-content-start `}
              >
                <span
                  className={`d-flex flex-row align-items-center justify-content-start col-12 sub-title fw-normal ${styles.firstRow} `}
                >
                  CO<sub>2</sub>&nbsp;saved
                </span>
                <span
                  className={`d-flex flex-row align-items-center justify-content-start col-12 mt-3 text-xxl fw-bold ${styles.lastRow} `}
                >
                  <span className="text-center  ">
                    {co2Saved} KgCO<sub>2</sub>/kWh
                  </span>
                </span>
              </div>
            </div>
            <div
              className={`${styles.viewAllBtnContainer} d-flex flex-row col-12 align-items-center justify-content-end `}
            >
              <span
                className={`${styles.viewAllBtn} d-flex flex-row align-items-center justify-content-center col-3`}
                onClick={() => {
                  redirect("/analytics");
                  setCurrentMenu("analytics");
                }}
              >
                View all
                <FontAwesomeIcon
                  icon={faArrowRight as IconProp}
                  className="ms-2"
                />
              </span>
            </div>
          </div>
          {/* <div
                className="d-flex flex-row   align-items-center justify-content-between mt-5"
                style={{ width: "97.5%" }}
              >
                <span className="d-flex flex-row col-4 ">
                  <div
                    className={`${styles.referralRect} d-flex flex-column align-items-start justify-content-start`}
                  >
                    <div
                      className={`${styles.titleRow} d-flex flex-row col-12 align-items-center justify-content-start light-title fw-bold`}
                    >
                      <img src={referral} className="me-2" /> Referral
                    </div>
                    <div
                      className={`d-flex flex-row col-10  align-items-center justify-content-between ${styles.endButtonsRow} `}
                    >
                      <div
                        className={`col-5  d-flex flex-row align-items-center justify-content-center h-100 ${styles.littleBtnWhite}`}
                      >
                        <img src={info} className="me-2" />
                        Info
                      </div>
                      <div
                        className={`col-6  d-flex flex-row align-items-center justify-content-center h-100 ${styles.littleBtnBlack}`}
                      >
                        Code
                        <img src={copyBlack} className="ms-2" />
                      </div>
                    </div>
                  </div>
                </span>
                <span className="d-flex flex-row col-7 ">
                  <div
                    className={`${styles.referralRect} d-flex flex-column align-items-start justify-content-start`}
                  >
                    <div
                      className={`${styles.titleRow} d-flex flex-row col-12 align-items-center justify-content-start light-title fw-bold`}
                    >
                      <img src={file} className="me-2" /> Add cart to Wallet
                    </div>
                  </div>
                </span>
              </div> */}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Home;
