import React, { useState } from "react";

import styles from "./SocialsPopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import instagramIcon from "../../assets/instagramWhite.svg";
import facebookIcon from "../../assets/facebookWhite.svg";
import linkedinIcon from "../../assets/linkedinWhite.svg";
import youtubeIcon from "../../assets/youtubeWhite.svg";

import xIcon from "../../assets/X_ICON.png";
import axiosHelper from "../../helpers/axiosHelper";
import { setPlatformContact } from "../../store/BusinessPlatformStore";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

interface SocialsPopUpProps {
  instagram: string;
  facebook: string;
  whatsapp: number;
  linkedin: string;
  x: string;
  youtube: string;
  tiktok: string;
  open: boolean;
  close: () => void;
}

const SocialsPopUp: React.FC<SocialsPopUpProps> = (props) => {
  const [popup, setPopup] = useState(false);
  const [instagram, setInstagram] = useState(
    props.instagram !== null
      ? props.instagram.substring(22, props.instagram.length)
      : ""
  );

  const [facebook, setFacebook] = useState(
    props.facebook !== null ? props.facebook : ""
  );

  const [linkedin, setLinkedin] = useState(
    props.linkedin !== null ? props.linkedin : ""
  );
  const [x, setX] = useState(props.x !== null ? props.x : "");
  const [youtube, setYoutube] = useState(
    props.youtube !== null ? props.youtube : ""
  );
  const [tiktok, setTiktok] = useState(
    props.tiktok !== null ? props.tiktok.substring(19, props.tiktok.length) : ""
  );
  const [whatsapp, setWhatsapp] = useState(props.whatsapp);

  const handleWhatsapp = () => {
    if (whatsapp === 1) {
      setWhatsapp(0);
    } else {
      setWhatsapp(1);
    }
  };

  const handleInstagramAccount = () => {
    let instagramLink = "";
    if (instagram.length > 1) {
      return (instagramLink =
        "https://instagram.com/" + instagram.substring(0, instagram.length));
    } else {
      return instagramLink;
    }
  };

  const handleTikTokAccount = () => {
    let tiktokLink = "";
    if (tiktok.length > 1) {
      return (tiktokLink =
        "https://tiktok.com/" + tiktok.substring(0, tiktok.length));
    } else {
      return tiktokLink;
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let instagramLink = handleInstagramAccount();
    let tiktokLink = handleTikTokAccount();

    console.log("submit");
    let data: updateSocialsInformationData = {
      instagram: instagramLink,
      facebook: facebook,
      linkedin: linkedin,
      whatsapp: whatsapp,
      twitter: x,
      youtube: youtube,
      tiktok: tiktokLink,
    };
    console.log(data);
    await axiosHelper
      .updateSocials(data)
      .then((response) => {
        console.log(response.updated);
        setPlatformContact(response.updated);
        setFacebook(response.updated.facebook);
        setInstagram(
          response.updated.instagram !== null
            ? response.updated.instagram.substring(22, props.instagram.length)
            : ""
        );
        setLinkedin(response.updated.linkedin);
        setTiktok(
          response.updated.tiktok !== null
            ? response.updated.tiktok.substring(19, props.tiktok.length)
            : ""
        );
        setYoutube(response.updated.youtube);
        setX(response.updated.twitter);
        props.close();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => props.close()}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content} col-12   `}>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 pt-5 pt-xl-2">
                <span className="light-title fw-bold">Social Networks</span>
              </div>
              <form onSubmit={handleSubmit} className="col-12">
                <div className="d-flex flex-column align-items-start justify-content-start mt-7  col-12">
                  <div
                    className={`d-flex flex-row col-12  align-items-center justify-content-start  ${styles.inputRow}`}
                  >
                    <span className="col-2  text-center  d-flex flex-row align-items-center text-xxl  ">
                      <img alt="instagramIcon" src={instagramIcon} />
                    </span>
                    <span
                      className={`col-8 d-flex flex-row align-items-center justify-content-center text-xxl `}
                    >
                      Instagram
                    </span>
                  </div>
                  <div
                    className={`d-flex flex-row col-12  mt-2 align-items-center justify-content-center ${styles.inputContainer}`}
                  >
                    <span className="col-8 d-flex flex-row ">
                      <input
                        type="text"
                        className={`ps-xl-5 text-xxl h-100  ${styles.inputField}`}
                        value={instagram}
                        placeholder="Insert your instagram username "
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </span>
                  </div>

                  {/* FACEBOOK */}
                  <div
                    className={`d-flex flex-row col-12  align-items-center justify-content-start mt-3 ${styles.inputRow}`}
                  >
                    <span className="col-2  text-center  d-flex flex-row align-items-center text-xxl  ">
                      <img alt="facebookIcon" src={facebookIcon} />
                    </span>
                    <span
                      className={`col-8 d-flex flex-row align-items-center justify-content-center text-xxl `}
                    >
                      Facebook
                    </span>
                  </div>

                  <div
                    className={`d-flex flex-row col-12  mt-2 align-items-center justify-content-center ${styles.inputContainer}`}
                  >
                    <span className="col-12 d-flex flex-row ">
                      <input
                        type="text"
                        className={`ps-xl-5 text-xxl h-100 ${styles.inputField}`}
                        value={facebook}
                        placeholder="Insert your facebook profile link "
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </span>
                  </div>
                  {/* LINKEDIN */}
                  <div
                    className={`d-flex flex-row col-12  align-items-center justify-content-start mt-3 ${styles.inputRow}`}
                  >
                    <span className="col-2  text-center  d-flex flex-row align-items-center text-xxl  ">
                      <img alt="linkedinIcon" src={linkedinIcon} />
                    </span>
                    <span
                      className={`col-8 d-flex flex-row align-items-center justify-content-center text-xxl `}
                    >
                      LinkedIn
                    </span>
                  </div>

                  <div
                    className={`d-flex flex-row col-12  mt-2 align-items-center justify-content-center ${styles.inputContainer}`}
                  >
                    <span className="col-12 d-flex flex-row ">
                      <input
                        type="text"
                        className={`ps-xl-5 text-xxl h-100 ${styles.inputField}`}
                        value={linkedin}
                        placeholder="Insert your linkedin profile link "
                        onChange={(e) => setLinkedin(e.target.value)}
                      />
                    </span>
                  </div>
                  {/* X */}
                  <div
                    className={`d-flex flex-row col-12  align-items-center justify-content-start mt-3 ${styles.inputRow}`}
                  >
                    <span className="col-2  text-center  d-flex flex-row align-items-center text-xxl  ">
                      <img alt="xIconIcon" src={xIcon} />
                    </span>
                    <span
                      className={`col-8 d-flex flex-row align-items-center justify-content-center text-xxl `}
                    >
                      Twitter / X
                    </span>
                  </div>

                  <div
                    className={`d-flex flex-row col-12  mt-2 align-items-center justify-content-center ${styles.inputContainer}`}
                  >
                    <span className="col-12 d-flex flex-row ">
                      <input
                        type="text"
                        className={`ps-xl-5 text-xxl h-100 ${styles.inputField}`}
                        value={x}
                        placeholder="Insert your twitter/x profile link "
                        onChange={(e) => setX(e.target.value)}
                      />
                    </span>
                  </div>
                  {/* TikTok */}
                  <div
                    className={`d-flex flex-row col-12  align-items-center justify-content-start mt-3 ${styles.inputRow}`}
                  >
                    <span className="col-2  text-center  d-flex flex-row align-items-center text-xxl  ">
                      {/* <img src={tiktokIcon} /> */}
                    </span>
                    <span
                      className={`col-8 d-flex flex-row align-items-center justify-content-center text-xxl `}
                    >
                      TikTok
                    </span>
                  </div>

                  <div
                    className={`d-flex flex-row col-12  mt-2 align-items-center justify-content-center ${styles.inputContainer}`}
                  >
                    <span className="col-12 d-flex flex-row ">
                      <input
                        type="text"
                        className={`ps-xl-5 text-xxl h-100 ${styles.inputField}`}
                        value={tiktok}
                        placeholder="Insert your tiktok username "
                        onChange={(e) => setTiktok(e.target.value)}
                      />
                    </span>
                  </div>

                  {/* YouTube */}
                  <div
                    className={`d-flex flex-row col-12  align-items-center justify-content-start mt-3 ${styles.inputRow}`}
                  >
                    <span className="col-2  text-center  d-flex flex-row align-items-center text-xxl  ">
                      <img alt="youtubeIcon" src={youtubeIcon} />
                    </span>
                    <span
                      className={`col-8 d-flex flex-row align-items-center justify-content-center text-xxl `}
                    >
                      YouTube
                    </span>
                  </div>

                  <div
                    className={`d-flex flex-row col-12  mt-2 align-items-center justify-content-center ${styles.inputContainer}`}
                  >
                    <span className="col-12 d-flex flex-row ">
                      <input
                        type="text"
                        className={`ps-xl-5 text-xxl h-100 ${styles.inputField}`}
                        value={youtube}
                        placeholder="Insert your youtube link "
                        onChange={(e) => setYoutube(e.target.value)}
                      />
                    </span>
                  </div>
                  {/* Whatsapp */}
                  <div
                    className={`d-flex flex-row col-12  align-items-center justify-content-start mt-3 ${styles.inputRow}`}
                  >
                    <span className="col-2  text-center  d-flex flex-row align-items-center text-xxl  ">
                      <FontAwesomeIcon
                        icon={faWhatsapp as IconProp}
                        style={{ fontSize: "25px" }}
                      />
                    </span>
                    <span
                      className={`col-8 d-flex flex-row align-items-center justify-content-center text-xxl `}
                    >
                      WhatsApp
                    </span>
                    <span className="col-2 ">
                      <label>
                        <input
                          type="checkbox"
                          className={`${styles.toggleInput}`}
                          id="toggleSwitch"
                          checked={whatsapp === 1}
                          onChange={() => handleWhatsapp()}
                        />
                        <div className={`${styles.toggle}`}>
                          <div className={`${styles.toggleKnob}`}></div>
                        </div>
                      </label>
                    </span>
                  </div>

                  <div
                    className={`d-flex flex-row col-12  mt-2 align-items-center justify-content-center ${styles.inputContainer}`}
                  >
                    <span className="col-12 d-flex flex-row ">
                      {/* <input
                        type="text"
                        className={`ps-xl-5 text-xxl h-100 ${styles.inputField}`}
                        value={youtube}
                        placeholder="Insert your youtube link "
                        onChange={(e) => setYoutube(e.target.value)}
                      /> */}
                    </span>
                  </div>

                  <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-5 ">
                    <button
                      type="submit"
                      className={`${styles.submitBtn} col-xl-3 col-5 text-xxl fw-bold`}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default SocialsPopUp;
