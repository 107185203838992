import React from "react";

import BusinessPlatformStore, {
  setCurrentMenu,
} from "../../store/BusinessPlatformStore";

import styles from "./UserPopUp.module.scss";
import { useStoreState } from "pullstate";
import { getPlatformContact, getPlatformUser } from "../../store/Selector";

import user from "../../assets/userWhite.svg";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const UserPopUp: React.FC = () => {
  const redirect = useNavigate();
  const platformUser = useStoreState(BusinessPlatformStore, getPlatformUser);
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);

  const handleLogout = async () => {
    console.log("logout");
    await axiosHelper.logout().then((response) => {
      redirect("/Login");
    });
  };
  return (
    <>
      <div
        className={`${styles.popUp} d-flex flex-column col-12 align-items-start justify-content-start `}
      >
        <div
          className="d-flex flex-row align-items-center justify-content-start col-12 pb-2"
          style={{ borderBottom: "1px solid white", height: "50px" }}
        >
          <div className={`col-2  ${styles.imageContainer}`}>
            {userContact.imageurl ? (
              <img alt="userImage" src={userContact.imageurl} />
            ) : (
              <div className="d-flex flex-row align-items-center justify-content-center col-12 h-100 bg-light-gray-3">
                <FontAwesomeIcon
                  icon={faUser as IconProp}
                  className="color-white"
                  style={{ fontSize: "3.9rem" }}
                />
              </div>
            )}
            {/* <img src={userContact.imageurl} /> */}
          </div>
          <div className="col-10 ms-3 d-flex flex-column align-items-start justify-content-start text">
            <span className="fw-bold overflow-hidden col-12">
              {platformUser.username}
              {/* Matteo Boniperti */}
            </span>
            <span className="overflow-hidden col-12">
              {platformUser.email}
              {/* matteo.boniperti@gmail.com */}
            </span>
          </div>
        </div>

        <div
          className="d-flex flex-row align-items-center justify-content-start col-12 pb-2 mt-2 cursor-pointer"
          style={{ borderBottom: "1px solid white", height: "40px" }}
          onClick={() => {
            redirect("/user-info");
            setCurrentMenu("user");
          }}
        >
          <div className={`col-1  ${styles.iconContainer}`}>
            <img alt="userIcon" src={user} />
          </div>
          <div className="col-11 ms-3 d-flex flex-column align-items-start justify-content-start text">
            <span className="fw-bold overflow-hidden col-12">
              Account Settings
            </span>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start col-12 pb-2 mt-2 ">
          <div
            className="col-12  d-flex flex-column align-items-start justify-content-start text cursor-pointer"
            onClick={() => handleLogout()}
          >
            <span className=" overflow-hidden col-12">Logout</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPopUp;
