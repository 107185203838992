import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "./Container";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Carica dinamicamente l'SDK di Facebook
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID as string, // L'App ID di Facebook
        cookie: false, // Abilita i cookie per tenere traccia della sessione
        xfbml: true, // Analizza i plugin sociali
        version: "v21.0", // Versione dell'API di Facebook
      });
    };

    // Carica l'SDK di Facebook in modo asincrono
    (function (d, s, id) {
      var js: any,
        fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  

  return (
    <Router>
      <Container />
    </Router>
  );
}

export default App;
